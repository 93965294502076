import "../../components/base.css";
import Layout from "../../components/layout";
import React from "react";


export default class Index extends React.Component {
  render() {
    return (
      <Layout>
        <div>
          <br></br>
          <h1>&gt; CHANNELS TO WATCH</h1>
        </div>
        <br></br>
        <div>
          <h2>RSI OFFICIAL /</h2>
            <h3>Roberts Space Industries official <a rel="noreferrer" target="_blank" href="https://www.youtube.com/user/RobertsSpaceInd">channel</a></h3>
            <h4>Official Youtube Channel</h4>
            <div class="video-container"><iframe title="RobertsSpaceInd" width="600" height="340" src="https://www.youtube.com/embed?listType=user_uploads&list=RobertsSpaceInd" frameborder="0" allowfullscreen></iframe>
              </div>
            </div>
        <div>
          <h2>THE NOOBIFIER /</h2>
          <h3>Noobifier's YouTube <a rel="noreferrer" target="_blank" href="https://www.youtube.com/user/BrianMP5T">channel</a></h3>
          <h4>Detailed breakdowns, Dude may know how to read, Useful</h4>
          <div class="video-container"><iframe title="The Noobifier" width="600" height="340" src="https://www.youtube.com/embed?listType=user_uploads&list=BrianMP5T" frameborder="0" allowfullscreen></iframe></div> 
        </div>
        <br></br>
        <div>
          <h2>SUPERMACBRO /</h2>
          <h3>SuperMacBro's YouTube <a rel="noreferrer" target="_blank" href="https://www.youtube.com/user/SuperMacBrothers">channel</a></h3>
          <h4>British dude, First looks, Covers patch notes, Evocati updates, Content leaks, May overlap with Bored Gamer</h4>
          <div class="video-container"><iframe title="SuperMacBrother" width="600" height="340" src="https://www.youtube.com/embed?listType=user_uploads&list=SuperMacBrothers" frameborder="0" allowfullscreen></iframe></div>  
        </div>
        <br></br>
        <div>
          <h2>SUBLIMINAL /</h2>
          <h3>SubliminaL's YouTube <a rel="noreferrer" target="_blank" href="https://www.youtube.com/user/SubliminaLsChannel">channel</a></h3>
          <h4>Good Ship Breakdowns, Buying Guide, Regular Updates</h4>
          <div class="video-container"><iframe title="SubliminaL" width="600" height="340" src="https://www.youtube.com/embed?listType=user_uploads&list=SubliminaLsChannel" frameborder="0" allowfullscreen></iframe></div> 
        </div>
        <br></br>
        <div>
          <h2>DIG THAT /</h2>
          <h3>Dig That's YouTube <a rel="noreferrer" target="_blank" href="https://www.youtube.com/user/DigThat32">channel</a></h3>
          <h4>Good Gameplay Guides, Smooth AF Voice, Concise</h4>
          <div class="video-container"><iframe title="Dig That" width="600" height="340" src="https://www.youtube.com/embed?listType=user_uploads&list=DigThat32" frameborder="0" allowfullscreen></iframe></div>  
        </div>
        <br></br>
        <div>
          <h2>AZURE-LANCE /</h2>
          <h3>Azure-Lance's YouTube <a rel="noreferrer" target="_blank" href="https://www.youtube.com/channel/Gianki12">channel</a></h3>
          <h4>Good PvP Guides, Combat Guides, Component Guides, Weapon Guides</h4>
          <div class="video-container"><iframe title="Azure Lance" width="600" height="340" src="https://www.youtube.com/embed?listType=user_uploads&list=Gianki12" frameborder="0" allowfullscreen></iframe></div>  
        </div>
        <br></br>
        <div>
          <h2>BOREDGAMER /</h2>
          <h3>BoredGamer's YouTube <a rel="noreferrer" target="_blank" href="https://www.youtube.com/user/BoredGamerUK">channel</a></h3>
          <h4>Rambling Star Citizen Content Creator, Some Useful Information</h4>
          <div class="video-container"><iframe title="Bored Gamer" width="600" height="340" src="https://www.youtube.com/embed?listType=user_uploads&list=BoredGamerUK" frameborder="0" allowfullscreen></iframe></div>  
        </div>
        <br></br>
      </Layout>
    );
  }
}